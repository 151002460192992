<ng-container *ngIf="!isVsLyAreaReady || !isVsLyChannelReady || !isVsLyRouteReady || !isVsLyDealerReady || !isVsLyTimeLineReady">
  <div class="text-center spinner-wrapper">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</ng-container>

<div *ngIf="isLoading" class="loading-overlay">
  <div class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
</div>

<div id="pdf_print_area_deepdive" #pdf_print_area_deepdive
[class]="isVsLyAreaReady && isVsLyChannelReady && isVsLyRouteReady && isVsLyDealerReady && isVsLyTimeLineReady ? '' : 'not-show' ">
  <div id="pdf_print_area_2" #pdf_print_area_2 class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card mb-30">
        <div class="area-title-header">
          <h2>Area Breakdown</h2>
        </div>

        <div class="filter-section even-wrapper">
          <div class="ng-section summary-section hq-root1-name select-sale-date">
            <b>Date Type</b>
            <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
            [searchable]="false"
            [virtualScroll]="true"
            [(ngModel)]="lstOption_Selected_DateType"
            [multiple]="false"
            [closeOnSelect]="false"
            (blur)="onDateTypeSaveItemsChange(true)"
            [clearable]="lstOption_Selected_SalesDate$.value.length > 1">
                <ng-option *ngFor="let item of lstOption_DateType" [value]="item.value">
                    <input type="checkbox" class="cb-multi-select"/>{{item.value}}
                </ng-option>
                <ng-template ng-footer-tmp>
                    <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected_DateType.length == 0"
                    (click)="onDateTypeSaveItemsChange(false);ngFilterDateType.close()">Aggregate</button>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items">
                    <ng-container *ngFor="let item of items; index as i">
                        <ng-container *ngIf="i==0">
                            <div class="text-over-dropdown">{{item}}</div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="items.length > 1">
                        ...
                    </ng-container>
                </ng-template>
            </ng-select>
          </div>

          <multi-dropdown
            *ngIf="lstOption_Aggregated_DateType && lstOption_Aggregated_DateType === 'MTD'"
            dataTitle="Sales Date"
            isNeedSortDisplayValue = true
            [keyName]="FILTERS?.SALES_DATE_NAME"
            [initialData]="lstOption_SalesDate$.value"
            [initialSelected]="lstOption_Selected_SalesDate$.value"
            [showTooltip]="false"
            (saveItemsChange)="onSalesDateSaveItemsChange($event)"></multi-dropdown>

          <div class="ng-section summary-section salesdate-section date-range-input" *ngIf="lstOption_Aggregated_DateType && lstOption_Aggregated_DateType !== 'MTD' && dailySalesDateRange.ready">
            <date-range-picker className="form-control input-date-range"
              [from] = "dailySalesDateRange.fromDate"
              [to] = "dailySalesDateRange.toDate"
              [maxDate] = "dailySalesDateRange.maxDate"
              [minDate] = "dailySalesDateRange.minDate"
              (dateRangeSelection)="onDateSelect($event)" pickerName="Sales Date" id="area-deepdive-date-picker">
            </date-range-picker>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-area-deepdive-vs-ly-area
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate$.value"
    (ready)="vsLyAreaReady($event)"></app-area-deepdive-vs-ly-area>

  <app-area-deepdive-vs-ly-channel
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate$.value"
    (ready)="vsLyChannelReady($event)"></app-area-deepdive-vs-ly-channel>

  <app-area-deepdive-vs-ly-route
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate$.value"
    (ready)="vsLyRouteReady($event)"></app-area-deepdive-vs-ly-route>

  <app-area-deepdive-vs-ly-dealer
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate$.value"
    (ready)="vsLyDealerReady($event)"></app-area-deepdive-vs-ly-dealer>

  <app-area-deepdive-vs-ly-timeline
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate$.value"
    (ready)="vsLyTimeLineReady($event)"></app-area-deepdive-vs-ly-timeline>
</div>
