import * as tsvParse from "d3-dsv";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { apiUrl, AreaDeepdiveQueries } from 'src/app/model/constants';
import { Router } from '@angular/router';
import { isObjEmpty, objectToQueryString, openWarningPopup, closeWarningPopup } from 'src/app/helpers/utils';

export class AreaDeepdiveHelper {
  private static dateInJobClearCache = new BehaviorSubject<string>('');
  private static loadingSubject = new BehaviorSubject<boolean>(false);
  private static loadingCount   = 0;
  private static userGroupName  = '';

  static setLoading(loading: boolean) {
    return this.loadingSubject.next(loading);
  }

  static isLoading() {
    return this.loadingSubject.asObservable();
  }

  static startLoading() {
    this.loadingCount++;
    this.loadingSubject.next(true);
  }

  static stopLoading() {
    this.loadingCount--;
    if (this.loadingCount === 0) {
      this.loadingSubject.next(false);
    }
  }

  static setDateInJobClearCache(value: string) {
    return this.dateInJobClearCache.next(value);
  }

  static getDateInJobClearCache() {
    return this.dateInJobClearCache.asObservable();
  }

  static GetAllFilterCalendarOptions(http: HttpClient, dateInJobClearCache: string, groupName: string): Promise<any> {
    if (this.dateInJobClearCache.value.length <= 0 && dateInJobClearCache.length > 0) {
      this.dateInJobClearCache.next(dateInJobClearCache);
    }

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      Code: AreaDeepdiveQueries.calendarFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.calendarFilter}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      Selected: {}
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
            resolve(JSON.parse(response));
          },
          error => {
            console.log(error);
          }
        );
    });
  }

  static GetFilterCalendarOptions(http: HttpClient, queryObj: object, groupName: string): Promise<any> {
    this.startLoading();

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.calendarFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.calendarFilter}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
            resolve(JSON.parse(response));
            this.stopLoading();
          },
          error => {
            console.log(error);
            this.stopLoading();
          }
        );
    });
  }

  static GetAllFilterAreaOptions(http: HttpClient, dateInJobClearCache: string, groupName: string): Promise<any> {
    if (this.dateInJobClearCache.value.length <= 0 && dateInJobClearCache.length > 0) {
      this.dateInJobClearCache.next(dateInJobClearCache);
    }

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      Code: AreaDeepdiveQueries.areaFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.areaFilter}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      Selected: {}
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));
          },
          error => console.log(error)
        );
    });
  }

  static GetFilterAreaOptions(http: HttpClient, queryObj: object, groupName: string): Promise<any> {
    this.startLoading();

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.areaFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.areaFilter}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));
              this.stopLoading();
          },
          error => {
            console.log(error);
            this.stopLoading();
          }
        );
    });
  }

  static GetAllFilterAccountOptions(http: HttpClient, dateInJobClearCache: string, groupName: string): Promise<any> {
    if (this.dateInJobClearCache.value.length <= 0 && dateInJobClearCache.length > 0) {
      this.dateInJobClearCache.next(dateInJobClearCache);
    }

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      Code: AreaDeepdiveQueries.accountFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.accountFilter}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      Selected: {}
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));
          },
          error => console.log(error)
        );
    });
  }

  static GetFilterAccountOptions(http: HttpClient, queryObj: object, groupName: string): Promise<any> {
    this.startLoading();

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.accountFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.accountFilter}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));
              this.stopLoading();
          },
          error => {
            console.log(error);
            this.stopLoading();
          }
        );
    });
  }

  static GetAllFilterProductOptions(http: HttpClient, dateInJobClearCache: string, groupName: string): Promise<any> {
    if (this.dateInJobClearCache.value.length <= 0 && dateInJobClearCache.length > 0) {
      this.dateInJobClearCache.next(dateInJobClearCache);
    }

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      Code: AreaDeepdiveQueries.productFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.productFilter}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      Selected: {}
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              if (response) {
                resolve(JSON.parse(response));
              }
          },
          error => console.log(error)
        );
    });
  }

  static GetFilterProductOptions(http: HttpClient, queryObj: object, groupName: string): Promise<any> {
    this.startLoading();

    if (!this.userGroupName.length && groupName.length > 0) {
      this.userGroupName = groupName;
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.productFilter,
      CacheKey: `?code=${AreaDeepdiveQueries.productFilter}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));
              this.stopLoading();
          },
          error => {
            console.log(error);
            this.stopLoading();
          }
        );
    });
  }

  static GetDataByArea(http: HttpClient, dateType: string, salesDate: string[], queryObj: object,isExport: boolean = false): Promise<any> {
    if (isExport) {
      openWarningPopup();
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.byArea,
      CacheKey: `?code=${AreaDeepdiveQueries.byArea}&IsExport=${isExport}&DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      Selected: {
        ...queryObj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      },
      IsExport : isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

  static GetDataByChannel(http: HttpClient, dateType: string, salesDate: string[], queryObj: object,isExport: boolean = false): Promise<any> {
    if (isExport) {
      openWarningPopup();
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.byChannel,
      CacheKey: `?code=${AreaDeepdiveQueries.byChannel}&IsExport=${isExport}&DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      Selected: {
        ...queryObj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      },
      IsExport : isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

  static GetDataByRoute(http: HttpClient, dateType: string, salesDate: string[], queryObj: object,isExport: boolean = false): Promise<any> {
    if (isExport) {
      openWarningPopup();
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.byRoute,
      CacheKey: `?code=${AreaDeepdiveQueries.byRoute}&IsExport=${isExport}&DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      Selected: {
        ...queryObj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      },
      IsExport : isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

  static GetDataByDealer(http: HttpClient, dateType: string, salesDate: string[], queryObj: object,isExport: boolean = false): Promise<any> {
    if (isExport) {
      openWarningPopup();
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.byDealer,
      CacheKey: `?code=${AreaDeepdiveQueries.byDealer}&IsExport=${isExport}&DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      Selected: {
        ...queryObj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      },
      IsExport : isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

  static GetDataByTimeline(http: HttpClient, dateType: string, salesDate: string[], queryObj: object,isExport: boolean = false): Promise<any> {
    if (isExport) {
      openWarningPopup();
    }

    const bodyObj = {
      ...queryObj,
      Code: AreaDeepdiveQueries.byTimeline,
      CacheKey: `?code=${AreaDeepdiveQueries.byTimeline}&IsExport=${isExport}&DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      Selected: {
        ...queryObj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      },
      IsExport : isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

  static getDateType(dateTypes: string[]): {value: string, text: string}[] {
    if (!dateTypes?.length) {
      return [];
    }

    return dateTypes.map(item => { return { value: item, text: item} })
  }

  static getDateTypeDefault() {
    return 'MTD';
  }

  static getSaleDate(dateType: any) {
    const temp = dateType;
    let data   = null;

    switch (temp) {
      case 'MTD':
        data = getLatest13Months();
        break;

      case 'Daily TYTM':
        data = getDailyThisYearThisMonth();
        break;

      case 'Daily TYLM':
        data = getDailyThisYearLastMonth();
        break;

      case 'Daily LYNM':
        data = getDailyLastYearNextMonth();

      default:
        break;
    }

    return data;
  }

  static distinctArray(inputArray: any[]): any[] {
    const uniqueValues = new Set(inputArray.map(item => item.value));

    const resultArray: any[] = Array.from(uniqueValues).map(value => {
           const correspondingItem = inputArray.find(item => item.value === value);
           return correspondingItem || { value, text: '' };
    });

    return resultArray;
  }

  static skuSetQueryString(lstOptionSelected: object){
    const analyticsQuery = {
      Selected  : {},
      UnSelected: {},
    };
    let cacheKey = '';

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key)) {
          if (!isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }

    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
  }

  static roundMaxValueInChart(num: number): number {
     let value = this.roundToFirstDigit(num);
     if(value ==0){ // if value == 0 set value =1 for gen chart
      value = 1;
     }
     return value;
  }

  static roundToFirstDigit (num: number): number {
    if(num === -Infinity) return 0;
    if (num === 0) return 0;

    const power = Math.floor(Math.log10(Math.abs(num)));
    const divisor = Math.pow(10, power);

    return Math.ceil(num / divisor) * divisor;
  };

  static fetchDataOnSearch(http: HttpClient, keyName: string, keySearch: string, currentPage: number) {
    let filterName: string  = '';

    switch (keyName) {
      case 'CHANNEL_LEV1_NAME':
      case 'CHANNEL_LEV2_NAME':
      case 'CHANNEL_LEV3_NAME':
        filterName = AreaDeepdiveQueries.calendarFilter;
        break;

      case 'DEALER_NAME':
      case 'SALES_HQ_LEV1_NAME':
      case 'SALES_HQ_LEV2_NAME':
      case 'SALES_HQ_LEV3_NAME':
      case 'SALES_CENTER_NAME':
      case 'SALES_ROUTE_NAME':
      case 'COM_DIST_SALES_REP_NAME':
        filterName = AreaDeepdiveQueries.areaFilter;
        break;

      case 'KAM_DEPT_LV2_NAME':
      case 'KAM_DEPT_LV3_NAME':
      case 'KAM_DEPT_LV4_NAME':
      case 'KAM_CONTACT_PERSON_NAME':
      case 'ACCOUNT_LEV2_NAME_EN':
      case 'ACCOUNT_LEV3_NAME_EN':
        filterName = AreaDeepdiveQueries.accountFilter;
        break;

      case 'CATEGORY_NAME_EN':
      case 'SUBCATEGORY_NAME_EN':
      case 'BRAND_NAME_EN':
      case 'PACKAGE_LEV1_NAME_EN':
      case 'PACKAGE_LEV2_NAME_EN':
      case 'PACKAGE_DTL_NAME':
        filterName = AreaDeepdiveQueries.productFilter;
        break;

      default:
        break;
    }

    return new Promise<any>((resolve, reject) => {
      const bodyObj = {
        Code: filterName,
        CacheKey: `?code=${filterName}&FilterKeySearch=${keyName}&${keyName}=${keySearch}&LazyLoad=${currentPage}&LatestUpdate=${this.dateInJobClearCache.value}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
        FilterKeySearch: keyName,
        Selected: {
          [keyName]: [keySearch]
        },
        LazyLoad: {
          [keyName]: {
            Page: currentPage
          }
        }
      };

      const httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
      };

      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          res => {
              if (keySearch.length >= 1) {
                let data = JSON.parse(res);
                resolve({
                  ...data,
                  Selected: {
                    [keyName]: [keySearch]
                  }
                });
              } else {
                resolve({});
              }
          },
          error => {
            console.log(error);
          }
        );
    });
  }

  static isAllSelected = (arr1: any) => {
    return JSON.stringify(arr1) === JSON.stringify(['All']);
  }
}

const isAllSelected = (arr1: any) => {
  return JSON.stringify(arr1) === JSON.stringify(['All']);
}

const getLatest13Months = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const currentYear = yesterday.getFullYear();
  const currentMonth = yesterday.getMonth() + 1;
  const latestMonths = [];

  for (let i = 0; i < 13; i++) {
    let month = currentMonth - i;
    let year = currentYear;

    if (month <= 0) {
        month += 12;
        year--;
    }

    const formattedMonth = month.toString().padStart(2, '0');
    latestMonths.push({ text: `${year}-${formattedMonth}`, value: `${year}-${formattedMonth}`});
  }

  latestMonths.push({ text: 'All', value: 'All' })
  return latestMonths.reverse();
}

const getDailyThisYearThisMonth = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const year = yesterday.getFullYear();
  const month = yesterday.getMonth();
  const firstDayOfMonth = new Date(year, month, 1);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return [formatDate(firstDayOfMonth), formatDate(yesterday)];
}

const getDailyThisYearLastMonth = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const year = yesterday.getFullYear();
  const month = yesterday.getMonth();
  const firstDayOfLastMonth = new Date(year, month - 1, 1);
  const lastDayOfLastMonth = new Date(year, month, 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return [formatDate(firstDayOfLastMonth), formatDate(lastDayOfLastMonth)];
}

const getDailyLastYearNextMonth = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const lastYear = yesterday.getFullYear() - 1;
  const nextMonth = yesterday.getMonth() === 11 ? 0 : yesterday.getMonth() + 1;
  const nextMonthYear = yesterday.getMonth() === 11 ? lastYear + 1 : lastYear;

  const firstDayOfNextMonth = new Date(nextMonthYear, nextMonth, 1);
  const lastDayOfNextMonth = new Date(nextMonthYear, nextMonth + 1, 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return [formatDate(firstDayOfNextMonth), formatDate(lastDayOfNextMonth)];
}
