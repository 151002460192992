<div class="row">
    <div class="col-lg-12 col-md-12">
        <div #scroll class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Value vs LY</h3>
                <!-- <div class="csv-download" [style]="'position: relative'">
                    <i class="bx bxs-arrow-to-bottom" (click)="showOptionDownload()"></i>
                    <div [id]="'download-option-otc-value'" style="display: none;" class="apexcharts-menu apexcharts-menu-open">
                        <div class="apexcharts-menu-item" title="Download PNG" (click)="exportOTCValuevsLY(true)">Download PNG</div>
                        <div class="apexcharts-menu-item" title="Download CSV" (click)="exportOTCValuevsLY(false)">Download CSV</div>
                    </div>
                </div> -->
            </div>
            <div class="filter-section no-title mb-4">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onOtcValueAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Weekly'}, {value: 'mtd', name: 'Monthly'}, {value: 'qtd', name: 'Quarterly'}, {value: 'ytd', name: 'Yearly'}]"
                    [model]="aggregateBy"
                    (change)="onOtcValueAggregateChange($event)"
                    >
                </single-dropdown>

                <moving-average-dropdown title="Display"
                    [items]="[{value: 4, name: '4-Week Moving Average'}]"
                    (change)="onMovingAverageWindowSizeChange($event)"
                    [(model)]="movingAverageWindowSizes"
                    >
                </moving-average-dropdown>

            </div>

            <div class="filter-radio title-channels">
                <div class="items">
                    <div class="item" *ngFor="let item of otcChannels; index as i;" [hidden]="shouldChannelBeHidden(item.channel)">
                        <input class="form-check-input" (change)="onOtcChannelCheckedChanged(item.channel)" type="checkbox"
                            value="{{item.channel}}" [checked]="item.checked"
                            [disabled]="otcHaveSingleSerie && item.channel === otcSingleSerie">
                        <label class="form-check-label">{{item.channel}}</label>
                    </div>
                </div>            
            </div>

            <div class="filter-section even-wrapper">

                <!-- <div class="ng-section summary-section native">
                    <b>Display</b>
                    <select class="form-control filter-by-category form-basic mr-2 mb-2" [(ngModel)]="selectedChannelType" (change)="onChannelCasesChange($event.target.value)">
                        <option value="1">Channel Level 1</option>
                        <option value="2">Channel Level 2</option>
                    </select> 
                </div> -->
                <single-dropdown title="Display"
                    [items]="[{value: '1', name: 'Channel Level 1'}, {value: '2', name: 'Channel Level 2'}]"
                    (change)="onChannelCasesChange($event)"
                    [(model)]="selectedChannelType"
                    >
                </single-dropdown>

                <div class="ng-section summary-section">
                    <b>Category</b>
                    <ng-select #ngFilterCategoryChannel class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="selectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterOTCCategory($event)"
                    (change)="onRemoveFilterOTCCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterOTCCategory($event)"
                    [clearable]="selectedCategory.length > 1">
                        <ng-option *ngFor="let item of categoriesFilter" [value]="item.category"><input type="checkbox" class="cb-multi-select"/> {{getCategoryDisplayName(item.category)}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="ngFilterCategoryChannel.close();SaveOptionValueTemp(false);onFilterByCategoryChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterOTCRegion class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="otcSelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterOTCRegion($event)"
                    (change)="onRemoveFilterOTCRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterOTCRegion($event)"
                    [clearable]="otcSelectedRegion.length > 1">
                        <ng-option *ngFor="let item of summaryRegions" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterOTCRegion.close();onFilterByOtcRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Package Type</b>
                    <ng-select #ngFilterPackageTypeOTC class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="otcSelectedPackageType[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterOTCPackageType($event)"
                    (change)="onRemoveFilterOTCPackageType($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterOTCPackageType($event)"
                    [clearable]="otcSelectedPackageType.length > 1">
                        <ng-option *ngFor="let item of summaryPackageTypes" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterPackageTypeOTC.close();onFilterByOtcPackageTypeChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Makers</b>
                    <ng-select #ngFilterMakersOTC class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="otcSelectedMaker[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterOTCMaker($event)"
                    (change)="onRemoveFilterOTCMaker($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterOTCMaker($event)"
                    [clearable]="otcSelectedMaker.length > 1">
                        <ng-option *ngFor="let item of marketShareMakers" [value]="item.maker"><input type="checkbox" class="cb-multi-select"/> {{item.maker}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterMakersOTC.close();onFilterByOtcMakerChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div #screen class="card-body">
                <div id="intage-otc-chart"></div>
                <ng-container *ngIf="otcHaveSingleSerie">
                    <div class="single-series-container {{aggregateBy ?? 'wtd'}} movingAverageWindowSizes-{{movingAverageWindowSizes.length}}">
                        <span class="red-dot" [ngStyle]="{backgroundColor: otcSingleSerieColor}"></span>
                        <span class="single-series">{{otcSingleSerie}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!otcHaveData">
                    <div class="no-data-container">
                        <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                    </div>
                </ng-container>
            </div>
            <div class="flex-two-ends">
                <!-- <div class="ng-section summary-section week-start native">
                    <b>Start week</b>
                    <select class="form-control" (change)="onSelectedStartWeekChanged($event.target.value)">
                        <option *ngFor="let item of otcWeeks; index as i;" value="{{otcWeeks.length - i}}" [selected]="otcWeeks.length - i === otcStartWeeks">{{item}}</option>
                    </select>
                </div> -->
                <single-dropdown title="Start Week"
                    [items]="dropdownValueToNgSelectFormat(otcWeeks)"
                    (change)="onSelectedStartWeekChanged($event)"
                    [model]="otcStartWeeks">
                </single-dropdown>
            </div>
            <div class="flex-two-ends mt-4 hide-pdf-print">            
                <!-- <comment-button title1="Value vs LY"
                [chartId]="chartId"
                [chartRef]="intageOtcChart"
                [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', movingAverageWindowSizes, otcChannels, selectedChannelType, selectedCategory, otcSelectedRegion, otcSelectedPackageType, otcSelectedMaker, otcWeeks, otcStartWeeks}}]"></comment-button> -->

                <download-button>
                    <button ngbDropdownItem (click)="exportOTCValuevsLY(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportOTCValuevsLY(false)">Download CSV</button>
                </download-button>
            </div>

            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>