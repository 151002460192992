import { Component, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import lodashClonedeep from 'lodash.clonedeep';
import { Subscription } from 'rxjs';
import moment from "moment";

import { authorize } from 'src/app/helpers/AuthorizePage';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import { AreaDeepdiveHelper} from './area-deepdive-helper';
import { FILTER_CONFIGS } from 'src/app/model/constants';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { SalesDateService } from '../../elements/sales-date-filter/sales-date-filter.service';
import { UserService } from 'src/app/services/user.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'app-area-deepdive',
  templateUrl: './area-deepdive.component.html',
  styleUrls: ['./area-deepdive.component.scss'],
  host     : {
    'class': 'se-report-content'
  }
})

export class AreaDeepdiveComponent implements OnInit, OnDestroy {
  FILTERS: any = FILTER_CONFIGS?.SKU_DEEPDIVE_DATE;

  isLoading: boolean = false;
  private loadingSubscription: Subscription;
  private salesDateSubscription: Subscription;

  isVsLyAreaReady: boolean;
  isVsLyChannelReady: boolean;
  isVsLyRouteReady: boolean;
  isVsLyDealerReady: boolean;
  isVsLyTimeLineReady: boolean;

  lstAllOptionsCalendarData: any;
  lstAllOptionsAreaData: any;
  lstAllOptionsAccountData: any;
  lstAllOptionsProductData: any;

  lstOption_DateType : any = [];
  lstOption_Selected_DateType: any = '';
  lstOption_Aggregated_DateType: any = '';

  lstOption_SalesDate$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  lstOption_Selected_SalesDate$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  userGroupName: string;

  dailySalesDateRange: any = {
    ready: false,
    fromDate: null,
    toDate : null,
    maxDate: null,
    minDate: null,
    previousFromDate: null,
    previousToDate: null
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private salesDateService: SalesDateService,
    private userService: UserService,
    private mixPanelTrackingService: MixPanelTrackingService
  ) {}

  async ngOnInit() {
    if(!authorize()){
      this.router.navigateByUrl('unauthorized');
    } else {
      this.salesDateService.getDateInJobClearCache();

      this.userGroupName = this.userService.getUserInfo() || '';
      this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          AreaDeepdiveHelper.GetAllFilterCalendarOptions(this.http, date[0], this.userGroupName).then((data) => {
            if (data) {
              this.lstAllOptionsCalendarData = data;
              this.lstOption_DateType        = AreaDeepdiveHelper.getDateType(['Daily LYNM', 'Daily TYLM', 'Daily TYTM', 'MTD']);

              const lstOption = FilterHelper.get_DateTypeAndSaleDate_States('AreaDeepdiveComponent');
              if (lstOption) {
                this.lstOption_Selected_DateType   = lstOption.lstOption_Selected_DateType;
                this.lstOption_Aggregated_DateType = lstOption.lstOption_Selected_DateType;

                if (this.lstOption_Selected_DateType !== 'MTD') {
                  this.dailySalesDateRange = {...lstOption.dailySalesDateRange} || {};
                }

                this.lstOption_Selected_SalesDate$.next(lstOption.lstOption_Selected_SalesDate);
              } else {
                this.lstOption_Selected_DateType   = AreaDeepdiveHelper.getDateTypeDefault();
                this.lstOption_Aggregated_DateType = AreaDeepdiveHelper.getDateTypeDefault();
                this.lstOption_Selected_SalesDate$.next(['All']);
              }

              this.lstOption_SalesDate$.next(AreaDeepdiveHelper.getSaleDate(this.lstOption_Selected_DateType));
            }
          });

          AreaDeepdiveHelper.GetAllFilterAreaOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsAreaData = data;
          });

          AreaDeepdiveHelper.GetAllFilterAccountOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsAccountData = data;
          });

          AreaDeepdiveHelper.GetAllFilterProductOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsProductData = data;
          });
        }
      });

      this.loadingSubscription = AreaDeepdiveHelper.isLoading().subscribe((loading: boolean) => {
        this.isLoading = loading;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  convertToNgbDateStruct(date: Date): NgbDateStruct {
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
 }

 private getOptionSaleDate(value1: string, value2: string, isInit: boolean) {
    const fromObj = new Date(value1);
    const toObj = new Date(value2);

    let todate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
    let fromdate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
    this.dailySalesDateRange.toDate   = todate;
    this.dailySalesDateRange.fromDate = fromdate;
    this.dailySalesDateRange.previousToDate = todate;
    this.dailySalesDateRange.previousFromDate = fromdate;

    if (isInit) {
      let maxDate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
      let minDate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
      this.dailySalesDateRange.maxDate = this.convertToNgbDateStruct(maxDate);
      this.dailySalesDateRange.minDate = this.convertToNgbDateStruct(minDate);
    }

    this.dailySalesDateRange.ready  = true;
  }

  public onDateTypeSaveItemsChange = (rollBack: boolean) => {
    if (!rollBack) {
      this.lstOption_Aggregated_DateType = lodashClonedeep(this.lstOption_Selected_DateType);

      if (this.lstOption_Aggregated_DateType === 'MTD') {
        this.lstOption_SalesDate$.next(AreaDeepdiveHelper.getSaleDate('MTD'));
        this.lstOption_Selected_SalesDate$.next(['All']);
      } else {
        const dates = AreaDeepdiveHelper.getSaleDate(this.lstOption_Aggregated_DateType);

        if (dates && Array.isArray(dates) && dates.length >= 2) {
          this.dailySalesDateRange.ready = false;
          this.lstOption_Selected_SalesDate$.next(dates);
          this.getOptionSaleDate(dates[0], dates[1], true);
          this.storeFilterOption();
        }
      }

      this.storeFilterOption();
      this.mixPanelTrackingService.onTrackDropdownAggregate('', 'Date Type', [this.lstOption_Aggregated_DateType]);
    } else {
      this.lstOption_Selected_DateType = lodashClonedeep(this.lstOption_Aggregated_DateType);
    }
  }

  public onSalesDateSaveItemsChange = (e: any) => {
    const {keyName, selectedItems} = e;

    if (keyName && selectedItems) {
      this.lstOption_Selected_SalesDate$.next(lodashClonedeep(selectedItems));

      this.storeFilterOption();
    }
  }

  public storeFilterOption(){
    FilterHelper.store_lstOption_States('AreaDeepdiveComponent',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.lstOption_Selected_DateType,
        this.lstOption_Selected_SalesDate$.value,
        this.dailySalesDateRange,
        this.userGroupName);
  }

  public onDateSelect(e: any) {
    if (e) {
      const { from, to } = e;

      if (from && to) {
        const startDate = moment(new Date(from)).format("YYYY-MM-DD");
        const endDate   = moment(new Date(to)).format("YYYY-MM-DD");

        if (!moment(startDate).isSame(this.dailySalesDateRange.previousFromDate) || !moment(endDate).isSame(this.dailySalesDateRange.previousToDate)) {
          this.lstOption_Selected_SalesDate$.next([startDate, endDate]);
          this.getOptionSaleDate(startDate, endDate, false);
          this.storeFilterOption();
        }

        dispatchDateSelectChange({
          selectedDateType: this.lstOption_Aggregated_DateType,
          selectedSalesDate: [startDate, endDate],
          ...{ element: 'area-deepdive' }, ...{ isDateRange: true } });
      }
    }
  }

  public vsLyAreaReady(ready: boolean){
    this.isVsLyAreaReady = ready;
  }

  public vsLyChannelReady(ready: boolean){
    this.isVsLyChannelReady = ready;
  }

  public vsLyRouteReady(ready: boolean){
    this.isVsLyRouteReady = ready;
  }

  public vsLyDealerReady(ready: boolean){
    this.isVsLyDealerReady = ready;
  }

  public vsLyTimeLineReady(ready: boolean){
    this.isVsLyTimeLineReady = ready;
  }
}
